import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const Deals = () => {
    const HOST = 'https://redfish-warm-absolutely.ngrok-free.app';
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const [totalPNL, setTotalPNL] = useState(0);
    const [todayPNL, setTodayPNL] = useState(0);
    const boolLookup = { 0: 'No', 1: 'Yes' };
    const typeLookup = { "SHORT": "SHORT", "LONG": "LONG" };
    const statusLookup = {
        "NEW": "NEW",
        "AWAITING": "AWAITING",
        "PARTIALLY_ENTERED": "PARTIALLY_ENTERED",
        "ENTERED": "ENTERED",
        "COMPLETED": "COMPLETED",
        "CANCELED": "CANCELED",
    };

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        const url = HOST + '/deals';
        const data = { "bot_name": localStorage.getItem("bot_name") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                var tempPNL = 0;
                var tempTodayPNL = 0;
                const currentTime = moment().unix() * 1000;
                for (var _item of result.result) {
                    tempPNL += _item.profit;
                    // console.log(_item)
                    // console.log(`currentTime: ${currentTime}, exit_timestamp: ${_item.exit_timestamp}. diff: ${currentTime - _item.exit_timestamp}`)
                    if (currentTime - _item.exit_timestamp <= 24 * 60 * 60 * 1000) {
                        tempTodayPNL += _item.profit;
                    }

                    if (_item.signal_timestamp !== 0) {
                        _item.signal_timestamp_date = moment(_item.signal_timestamp).format("MMM DD, HH:mm:ss.SSS");
                    } else {
                        _item.signal_timestamp_date = ""
                    }
                    // _item.signal_timestamp = moment(_item.signal_timestamp).format("MMM DD, HH:mm:ss.SSS");
                    
                    if (_item.enter_timestamp !== 0) {
                        _item.enter_timestamp_date = moment(_item.enter_timestamp).format("MMM DD, HH:mm:ss.SSS");
                    } else {
                        _item.enter_timestamp_date = ""
                    }

                    if (_item.exit_timestamp !== 0) {
                        _item.exit_timestamp_date = moment(_item.exit_timestamp).format("MMM DD, HH:mm:ss.SSS");
                    } else {
                        _item.exit_timestamp_date = ""
                    }

                    if (_item.last_updated_at !== 0) {
                        _item.last_updated_at = moment(_item.last_updated_at).format("MMM DD, HH:mm:ss.SSS");
                    } else {
                        _item.last_updated_at = ""
                    }

                    if (_item.pattern_last_candle_time !== 0) {
                        _item.pattern_last_candle_date = moment(_item.pattern_last_candle_time).format("MMM DD, HH:mm:ss");
                    } else {
                        _item.pattern_last_candle_date = ""
                    }

                    _item.pattern_price_change = _item.pattern_price_change.toFixed(2)
                }
                setData(result.result.reverse());
                setTotalPNL(tempPNL.toFixed(2));
                setTodayPNL(tempTodayPNL.toFixed(2));

                setLoadingState(false);
            });
    };


    var columns = [
        { title: "ID", field: "id", align: "left", editable: "never" },
        { title: "Signal ID", field: "signal_id", align: "left", editable: "never" },
        { title: "Status", field: "status", align: "left", editable: "never", lookup: statusLookup },
        { title: "Type", field: "type", align: "left", editable: "never", lookup: typeLookup },
        { title: "Symbol", field: "symbol", align: "left", editable: "never" },
        { title: "Signal Date", field: "signal_timestamp_date", align: "left", editable: "never", hidden: false },
        { title: "Signal Timestamp", field: "signal_timestamp", align: "left", editable: "never", hidden: true },
        { title: "Signal Enter Price", field: "signal_enter_price", align: "left", editable: "never", hidden: false, searchable: false, filtering: false },
        { title: "Enter Price", field: "enter_price", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Pattern End Time", field: "pattern_last_candle_time", align: "left", editable: "never", hidden: true },
        { title: "Pattern End Date", field: "pattern_last_candle_date", align: "left", editable: "never", hidden: false },
        { title: "Pattern Price Change %", field: "pattern_price_change", align: "left", editable: "never", hidden: false, },
        { title: "Pattern Volume", field: "pattern_volume", align: "left", editable: "never", hidden: true },
        { title: "Pattern Length", field: "pattern_length", align: "left", editable: "never", hidden: true },
        { title: "Enter Date", field: "enter_timestamp_date", align: "left", editable: "never" },
        { title: "Enter Timestamp", field: "enter_timestamp", align: "left", editable: "never", hidden: true },
        { title: "Is Profit", field: "is_profit", align: "left", editable: "never", lookup: boolLookup, hidden: true },
        { title: "Take Reached", field: "take_reached", align: "left", editable: "never", hidden: true },
        { title: "Position", field: "position_size", align: "left", editable: "never" },
        { title: "Actual Position", field: "actual_position", align: "left", editable: "never" },
        { title: "Actual Enter Price", field: "actual_enter_price", align: "left", editable: "never", hidden: true },
        { title: "Exit Price", field: "exit_price", align: "left", editable: "never", hidden: false },
        { title: "Exit Date", field: "exit_timestamp_date", align: "left", editable: "never" },
        { title: "Exit Timestamp", field: "exit_timestamp", align: "left", editable: "never", hidden: true },
        { title: "Profit", field: "profit", align: "left", editable: "never", searchable: false, filtering: false },
        { title: "Last Updated Date", field: "last_updated_at", align: "left", editable: "never", hidden: true },
        { title: "Comment", field: "comment", align: "left", editable: "never" },
        { title: "Bot ID", field: "bot_id", align: "left", editable: "never", editable: "never", hidden: false, filtering: true },
    ];


    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <div className="">
                    <div className="m-3">
                        Total PNL: {totalPNL}$. Last 24h PNL: {todayPNL}$
                    </div>
                    <MaterialTable
                        columns={columns}
                        data={data}
                        title="Deals"
                        options={{
                            // addRowPosition: "first",
                            search: false,
                            columnsButton: true,
                            filtering: true,
                            pageSize: 50,
                            rowStyle: (rowData) => {
                                var color = "#FFFFFF";
                                if (parseFloat(rowData.profit) < 0.0) {
                                    color = '#f5a9a9';
                                } else if (parseFloat(rowData.profit) > 0.0) {
                                    color = '#a7d9a8';
                                }
                                return {
                                    backgroundColor: color,
                                    fontSize: "14px"
                                }
                            },
                            headerStyle: {
                                fontWeight: "bold"
                            },
                            filterCellStyle: {
                                paddingTop: "0px",
                                paddingBottom: "8px",
                            }
                        }}
                    // localization={{ toolbar: { searchPlaceholder: 'Search code' } }}
                    // cellEditable={{
                    //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    //         return new Promise((resolve, reject) => {
                    //             console.log(rowData);
                    //             console.log('newValue: ' + newValue);
                    //             setTimeout(resolve, 1000);
                    //         });
                    //     },
                    //     isCellEditable: (rowData, columnDef) => {
                    //         return columnDef.field === "is_blocked"
                    //     }
                    // }}
                    // editable={{
                    //     onRowAdd: newData =>
                    //         new Promise((resolve, reject) => {
                    //             if (newData.purchase_code != undefined &&
                    //                 newData.purchase_code.length > 3 &&
                    //                 newData.packs_names != undefined &&
                    //                 newData.pack_author != undefined) {
                    //                 const packsArray = newData.packs_names.split(",");
                    //                 for (const _packName of packsArray) {
                    //                     if (!packNames.includes(_packName.trim())) {
                    //                         alert(`Pack ${_packName.trim()} does not exist`);
                    //                         reject();
                    //                         return;
                    //                     }
                    //                 }
                    //                 const url = 'https://adapi.motionbro.com/addCode';
                    //                 const _data = {
                    //                     "sk": localStorage.getItem("sk"), "purchase_code": newData.purchase_code,
                    //                     "packs_names": packsArray, "pack_author": newData.pack_author
                    //                 };
                    //                 fetch(url, { // optional fetch options
                    //                     body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                    //                     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    //                     credentials: 'same-origin', // include, same-origin, *omit
                    //                     headers: {
                    //                         'content-type': 'application/json'
                    //                     },
                    //                     method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    //                     mode: 'cors', // no-cors, cors, *same-origin
                    //                     redirect: 'follow', // *manual, follow, error
                    //                     referrer: 'no-referrer', // *client, no-referrer
                    //                 })
                    //                     .catch(e => {
                    //                         console.log(e)
                    //                         alert("Something is wrong. Incorrect pack author??");
                    //                         reject();
                    //                         return;
                    //                     })
                    //                     .then(response => {
                    //                         console.log(response);
                    //                         return response.json();
                    //                     })
                    //                     .catch(e => {
                    //                         console.log(e)
                    //                         alert("Something is wrong. Incorrect pack author??");
                    //                         reject();
                    //                         return;
                    //                     })
                    //                     .then(result => {
                    //                         if (result.result === "success") {
                    //                             var _item = result.code;
                    //                             _item.added_timestamp = moment(_item.added_timestamp * 1000).format();
                    //                             if (_item.last_used_timestamp != null) {
                    //                                 _item.last_used_timestamp = moment(_item.last_used_timestamp * 1000).format();
                    //                             }
                    //                             setData([_item, ...data]);

                    //                             resolve();
                    //                         } else {
                    //                             resolve();
                    //                         }
                    //                     });
                    //             } else {
                    //                 reject();
                    //             }

                    //         }),
                    //     onRowUpdate: (newData, oldData) =>
                    //         new Promise((resolve, reject) => {
                    //             newData.is_blocked = parseInt(newData.is_blocked);
                    //             if (newData.is_blocked !== oldData.is_blocked) {
                    //                 const url = 'https://adapi.motionbro.com/updateCode';
                    //                 const _data = { "sk": localStorage.getItem("sk"), "purchase_code": oldData.purchase_code, "is_blocked": newData.is_blocked }
                    //                 fetch(url, { // optional fetch options
                    //                     body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                    //                     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    //                     credentials: 'same-origin', // include, same-origin, *omit
                    //                     headers: {
                    //                         'content-type': 'application/json'
                    //                     },
                    //                     method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    //                     mode: 'cors', // no-cors, cors, *same-origin
                    //                     redirect: 'follow', // *manual, follow, error
                    //                     referrer: 'no-referrer', // *client, no-referrer
                    //                 })
                    //                     .then(response => response.json())
                    //                     .then(result => {
                    //                         if (result.result === "success") {
                    //                             const dataUpdate = [...data];
                    //                             const index = oldData.tableData.index;
                    //                             dataUpdate[index] = newData;
                    //                             setData([...dataUpdate]);

                    //                             resolve();
                    //                         }
                    //                     });
                    //             } else {
                    //                 reject();
                    //             }
                    //         }),
                    //     onRowDelete: localStorage.getItem("role") === "admin" ? oldData =>
                    //         new Promise((resolve, reject) => {
                    //             setTimeout(() => {
                    //                 const url = 'https://adapi.motionbro.com/deleteCode';
                    //                 const _data = {
                    //                     "sk": localStorage.getItem("sk"), "purchase_code": oldData.purchase_code
                    //                 }
                    //                 fetch(url, { // optional fetch options
                    //                     body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                    //                     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    //                     credentials: 'same-origin', // include, same-origin, *omit
                    //                     headers: {
                    //                         'content-type': 'application/json'
                    //                     },
                    //                     method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    //                     mode: 'cors', // no-cors, cors, *same-origin
                    //                     redirect: 'follow', // *manual, follow, error
                    //                     referrer: 'no-referrer', // *client, no-referrer
                    //                 })
                    //                     .then(response => response.json())
                    //                     .then(result => {
                    //                         if (result.result === "success") {
                    //                             const dataDelete = [...data];
                    //                             const index = oldData.tableData.id;
                    //                             dataDelete.splice(index, 1);
                    //                             setData([...dataDelete]);

                    //                             resolve();
                    //                         }
                    //                     });
                    //             }, 1000)
                    //         }) : null,
                    // }}
                    />
                </div>
        }
    </div>;
} 